import React from 'react';
import {
  Drawer,
  makeStyles,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Grid
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { appProfileSelector } from 'redux/selectors';
import classNames from 'classnames';
import GetMenus from './menus';
import useRouter from 'utils/useRouter';
import { logout } from 'redux/actions/auth';
import Notifications from '../Notifications'
import { logoURL } from 'config';

const APAVEIMAGE = require('assets/images/apave.png');
export const drawerWidth = 230;
export const drawerCloseWidth = 60;

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    width: drawerWidth,
    border: 'none',
    //borderTopRightRadius: theme.spacing(4),
    //borderBottomRightRadius: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      border: 'none',
      position: 'relative',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    backgroundColor: theme.palette.app.primary,
    transition: 'all .5s ease',
  },
  drawerClosePaper: {
    width: drawerCloseWidth,
    border: 'none',
    [theme.breakpoints.up('md')]: {
      width: drawerCloseWidth,
      border: 'none',
      position: 'relative',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    backgroundColor: theme.palette.app.primary,
    transition: 'all .5s ease',
  },
  drawerContainer: {
    position: 'fixed',
    left: 0,
    top: 0,
    bottom: 0,
    zIndex: 2
  },
  drawerInner: {
    width: drawerWidth,
    height: '100%',
  },
  menuList: {
    height: '90%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    maxHeight: 800,
    minHeight: 500,
  },
  link: {
    textDecoration: 'none',
    background: 'none',
    border: 'none',
    width: '100%',
    padding: 0,
    cursor: 'pointer',
    color: theme.palette.app.primary,
    wordBreak: 'break-all',
  },
  listItemText: {
    fontSize: 14,
  },
  logo: {
    maxWidth: 160,
    maxHeight: 100,
    backgroundColor: 'white',
    borderRadius: '0px 0px 30px 30px'
  },
  activebar: {
    position: 'absolute',
    left: 0,
    width: 3,
    top: 0,
    bottom: 0,
    zIndex: 1,
    backgroundColor: 'white'
  },
  closeSideMenuItem: {
    paddingLeft: '21px !important'
  }
}));

const NavBar = ({ openDrawer, setOpenDrawer }) => {
  const classes = useStyles();
  const profile = useSelector(appProfileSelector);
  const { role, companyLogo, logo } =profile
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const menus = GetMenus(role);
  const router = useRouter();
  const pathname = String(router.history.location.pathname).toLowerCase();
  const dispatch = useDispatch();
  const isActive = item => {
    return !open && pathname.includes(String(item.id).toLowerCase());
  };
  const onSignOut = () => {
    dispatch(logout());
  };
  const openNewUrl = (link) => {
    const win = window.open(link, "_blank");
    win.focus();
  }
  const logoImage = companyLogo || logo;
  return (
    <Drawer
      id="sidebar-menu"
      anchor="left"
      open={true}
      classes={{ paper: openDrawer ? classes.drawerPaper : classes.drawerClosePaper, root: classes.drawerContainer }}
      ModalProps={{
        keepMounted: true,
      }}
      variant="permanent"
      onMouseOver={(e) => {
        e.preventDefault();
        setOpenDrawer(true)
      }}
      onMouseLeave={(e) => {
        e.preventDefault();
        setOpenDrawer(false)
      }}
    >
      <div className={classes.drawerInner}>
        <div className={classes.menuList}>
          {
            openDrawer && logoImage ?
              <Grid container justify="center">
                <img src={logoURL + logoImage} alt="apave" className={classes.logo} />
              </Grid> :
              <div style={{ height: 75 }} />
          }
          <MenuList>
            {menus.middle.map((item, index) => (
              <div
                key={`id-${item.id}`}
                className={classNames(classes.link, 'side_menu-item')}
                onClick={() => {
                  if (item.href) {
                    openNewUrl(item.href)
                    return
                  }
                  router.history.push(item.url);
                }}
                role="button"
                tabIndex={index}
              >
                {
                  isActive(item) && <div className={classes.activebar} />
                }
                <MenuItem
                  className={classNames(classes.menuitem, isActive(item) ? 'menu-item--selected' : '', { [classes.closeSideMenuItem]: !openDrawer })}
                >
                  <ListItemIcon className={'menu-icon'}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    inset
                    disableTypography
                    className={classNames(
                      classes.listItemText,
                      'truncate-list-item-text',
                      'menu-text',
                    )}
                  />
                </MenuItem>
              </div>
            ))}
          </MenuList>
          <MenuList>
            {menus.bottom.map((item, index) => (
              <div
                key={`id-${item.id}`}
                className={classNames(classes.link, 'side_menu-item')}
                onClick={() => {
                  if (item.href) {
                    openNewUrl(item.href)
                    return
                  }
                  if (item.id === 'exit') {
                    return onSignOut();
                  }
                  router.history.push(item.url);
                }}
                role="button"
                tabIndex={index}
              >
                {
                  isActive(item) && <div className={classes.activebar} />
                }
                <MenuItem
                  className={classNames(isActive(item) ? 'menu-item--selected' : '', { [classes.closeSideMenuItem]: !openDrawer })}
                >
                  <ListItemIcon className={'menu-icon'}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    inset
                    disableTypography
                    className={classNames(
                      classes.listItemText,
                      'truncate-list-item-text',
                      'menu-text',
                    )}
                  />
                </MenuItem>
              </div>
            ))}
          </MenuList>
        </div>
      </div>
      <Notifications
        id={id}
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
      />
    </Drawer>
  );
};

export default NavBar;
