import React, { Fragment, Suspense, useEffect } from 'react';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress, Button, Toolbar, AppBar, Badge, Hidden, IconButton, Popover, Typography } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { loggedInSelector, appLoadingSelector } from 'redux/selectors';
import Loader from 'components/Loader';
import useRouter from 'utils/useRouter';
import TabImage from 'assets/images/tabimage.png';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as IconCart } from 'assets/svgs/product_cart.svg';
import { ContactModal } from 'components/Modals'
import { setContactModal } from 'redux/actions/app'
import IconMenu from '@material-ui/icons/Menu';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  appBar: {
    width: `100%`,
    height: 64,
    minHeight: 'auto',
    boxShadow: '0 3px 3px -1px rgb(0 0 0 / 20%)',
    transition: 'all .5s ease',
    backgroundColor: 'white'
  },
  content: {
    //height: '100vh',
    ///backgroundImage: 'url(/images/background.jpg)',
    //backgroundSize: 'cover',
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    backgroundColor: 'white'
  },
  signup: {
    backgroundColor: theme.palette.app.lightBlue,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    borderRadius: 20,
  },
  logo: {
    height: 30,
  },
  header: {
    display: 'flex',
    justifyContent: "space-between",
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(8),
    backgroundColor: theme.palette.app.primary
  },
  signupBtn: {
    textTransform: 'none',
    borderRadius: 8,
    margin: theme.spacing(0.5, 1),
    border: "1px solid white",
    "&:hover": {
      border: '1px solid transparent'
    }
  },
  disabledBtn: {
    color: `${'white'} !important`,
    border: 'none'
  },
  tabButton: {
    color: theme.palette.app.primary,
    backgroundImage: `url(${TabImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    padding: '5px 50px',
    backgroundColor: 'rgba(38, 50, 56, 0.04)',
    marginBottom: -1
  },
  InactivetabButton: {
    color: 'white',
    textTransform: 'none',
    marginBottom: 3,
    marginRight: theme.spacing(2)
  },
  toolbar: {
    flex: 1,
    alignItems: 'flex-end',
    paddingLeft: '2%',
    paddingRight: '2%',
    backgroundColor: theme.palette.app.primary,
    marginBottom: 10,
    minHeight: 'unset !important'
  },
  clientBtn: {
    margin: theme.spacing(0, 4, 0, 2),
    color: '#6CB570',
    backgroundColor: 'white',
    borderRadius: 10,
    borderBottomLeftRadius: '0px !important',
    borderBottomRightRadius: '0px !important',
    fontSize: 12,
    fontWeight: 'bold',
    padding: '6px 12px',
    '&:hover': {
      backgroundColor: 'white !important'
    }
  },
  clientBtnInSm: {
    marginTop: theme.spacing(2),
    color: '#6CB570',
    backgroundColor: 'white',
    borderRadius: 10,
    fontSize: 12,
    fontWeight: 'bold',
    padding: '6px 12px',
    '&:hover': {
      backgroundColor: 'white !important'
    }
  },
  clientBtnLabel: {
    display: 'block'
  },
  indicator: {
    width: 65,
    height: 5,
    backgroundColor: '#6CB570',
    borderRadius: 10,
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: 4
  },
  middle: {
    whiteSpace: 'nowrap',
    color: '#868aa8',
    flex: 1,
    fontWeight: 'bold',
    textAlign: 'right'
  },
  inner: {
    marginTop: 64,
    height: 'calc(100vh - 64px)'
  },
  iconbutton: {
    color: theme.palette.text.primary,
    fontWeight: '600',
    textTransform: 'none'
  },
  btnIcon: {
    fill: theme.palette.app.primary,
    width: 20,
    height: 20
  },
  badge: {
    backgroundColor: 'white',
    color: 'red',
    right: 23,
    fontSize: 8,
    top: 13,
    width: 16,
    height: 16,
    minWidth: 16
  },
  iconWrap: {
    width: 32,
    height: 32,
    backgroundColor: 'white',
    borderRadius: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  popoverRoot: {
    backgroundColor: theme.palette.text.primary,
    borderRadius: 4,
    padding: 16,
    textAlign: "center"
  }
}));
const APAVEIMAGE = require('assets/images/apave.png');
const UnAuthLayout = props => {
  const { route, location } = props;

  const classes = useStyles();
  const isLoading = useSelector(appLoadingSelector);
  const { inProducts, apaveProducts } = useSelector(state => state.cart)
  const router = useRouter();
  const onSignIn = () => {
    router.history.push('/auth/login');
  };
  const { pathname } = useLocation();
  useEffect(() => {
    window.sp_track_simple();
  }, [pathname])

  const { openContactModal } = useSelector(state => state.app)
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpen = () => {
    dispatch(setContactModal(true))
  };
  const open = Boolean(anchorEl);
  const id = open ? 'top-menu' : undefined;
  return (
    <Fragment>
      <main className={classes.content}>
        <AppBar
          elevation={0}
          position="fixed"
          color="default"
          className={classes.appBar}
        >
          <Toolbar className={classes.toolbar}>
            {/* Mobile version */}
            <Hidden mdUp>
              <Button className={classes.tabButton}>
                <img src={APAVEIMAGE} alt="apave" className={classes.logo} />
              </Button>
              <IconButton aria-describedby={id} style={{ marginLeft: 'auto', color: "white" }} onClick={handleClick}>
                <IconMenu />
              </IconButton>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <div className={classes.popoverRoot}>
                  <Button className={classes.InactivetabButton}>
                    Espace Client
                  </Button>
                  {
                    !(location.pathname && location.pathname.includes("/pack-prelevements-sanitaires")) &&
                    <div>
                      <Button size="small" disabled className={classes.signupBtn} classes={{ disabled: classes.disabledBtn }}>
                        <FormattedMessage id="haveaccount" />
                      </Button>
                      <Button size="small" variant="contained" color="primary" className={classes.signupBtn} onClick={onSignIn}>
                        <FormattedMessage id="signin" />
                      </Button>
                      &nbsp;&nbsp;&nbsp;
                      <Badge badgeContent={apaveProducts.length} classes={{ badge: classes.badge }}>
                        <Button
                          startIcon={
                            <div className={classes.iconWrap}>
                              <IconCart className={classes.btnIcon} />
                            </div>}
                          className={classes.iconbutton}
                          onClick={() => router.history.push('/invite/carts')}>
                        </Button>
                      </Badge>
                    </div>
                  }
                  <Button
                    classes={{
                      root: classes.clientBtnInSm,
                      label: classes.clientBtnLabel
                    }}
                  >
                    {'Espace client'}
                    <div className={classes.indicator} />
                  </Button>
                </div>
              </Popover>
            </Hidden>


            {/* Desktop version */}
            <Hidden smDown>
              <Button className={classes.tabButton}>
                <img src={APAVEIMAGE} alt="apave" className={classes.logo} />
              </Button>
              <Button className={classes.InactivetabButton}>
                Espace Client
              </Button>
              <div className={classes.middle}>

              </div>
              {
                !(location.pathname && location.pathname.includes("/pack-prelevements-sanitaires")) &&
                <div>
                  <Button size="small" disabled className={classes.signupBtn} classes={{ disabled: classes.disabledBtn }}>
                    <FormattedMessage id="haveaccount" />
                  </Button>
                  <Button size="small" variant="contained" color="primary" className={classes.signupBtn} onClick={onSignIn}>
                    <FormattedMessage id="signin" />
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  <Badge badgeContent={apaveProducts.length} classes={{ badge: classes.badge }}>
                    <Button
                      startIcon={
                        <div className={classes.iconWrap}>
                          <IconCart className={classes.btnIcon} />
                        </div>}
                      className={classes.iconbutton}
                      onClick={() => router.history.push('/invite/carts')}>
                    </Button>
                  </Badge>
                </div>
              }
              <div>
                <Button
                  classes={{
                    root: classes.clientBtn,
                    label: classes.clientBtnLabel
                  }}
                >
                  {'Espace client'}
                  <div className={classes.indicator} />
                </Button>
              </div>
            </Hidden>
          </Toolbar>
        </AppBar>
        <div className={classes.inner}>
          <Suspense fallback={<LinearProgress />}>
            {renderRoutes(route.routes)}
          </Suspense>
          {isLoading && <Loader />}
        </div>
      </main>
      <ContactModal
        appId="0000"
        visible={openContactModal}
        onClose={(refresh) => {
          dispatch(setContactModal(false))
        }}
      />
    </Fragment>
  );
};

UnAuthLayout.propTypes = {
  route: PropTypes.object,
};

export default UnAuthLayout;
