import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthLayout from 'layouts/AuthLayout';
import HomeLayout from 'layouts/HomeLayout';
import UnAuthLayout from 'layouts/UnAuthLayout';
import { authServerURL } from 'config';

const routes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
  {
    path: '/invite',
    component: UnAuthLayout,
    routes: [
      {
        path: '/invite',
        exact: true,
        component: lazy(() => import('pages/Catalogue')),
      },
      {
        path: '/invite/pack-prelevements-sanitaires',
        exact: true,
        component: lazy(() => import('pages/PackPage')),
      },
      {
        path: '/invite/carts',
        exact: true,
        component: lazy(() => import('pages/Carts')),
      },
      {
        path: '/invite/cyber_products',
        exact: true,
        component: lazy(() => import('pages/Catalogue/components/CyberProducts')),
      },
      {
        path: '/invite/verification-caces',
        exact: true,
        component: lazy(() => import('pages/VerificationCACES')),
      },
    ]
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('pages/Login')),
      },
      // {
      //   path: '/auth/signup',
      //   exact: true,
      //   component: lazy(() => import('pages/SignUp')),
      // },
      {
        path: '/auth/forgot-password',
        exact: true,
        component: lazy(() => import('pages/ForgotPassword')),
      },
      {
        path: '/auth/reset-password',
        component: lazy(() => import('pages/ResetPassword')),
      },
      {
        path: '/auth/send-verify-email',
        exact: true,
        component: lazy(() => import('pages/VerifyEmailSend')),
      },
      {
        path: '/auth/verification',
        component: lazy(() => import('pages/EmailVerification')),
      },
      {
        component: () => <Redirect to="/auth/login" />,
      },
    ],
  },
  {
    path: '*',
    component: HomeLayout,
    routes: [
      {
        path: '/profile',
        exact: true,
        component: lazy(() => import('pages/ProfileSettings')),
      },
      {
        path: '/dashboard',
        exact: true,
        component: lazy(() => import('pages/Dashboard_New')),
      },
      {
        path: '/planning',
        exact: true,
        component: lazy(() => import('pages/Plainning')),
      },
      {
        path: '/documents',
        exact: true,
        component: lazy(() => import('pages/DocumentsApave')),
      },
      {
        path: '/applications',
        exact: true,
        component: lazy(() => import('pages/Applications')),
      },
      {
        path: '/formations',
        exact: true,
        component: lazy(() => import('pages/Training')),
      },
      {
        path: '/my-training',
        exact: true,
        component: lazy(() => import('pages/Training')),
      },
      {
        path: '/catalogue',
        exact: true,
        component: lazy(() => import('pages/Catalogue')),
      },
      {
        path: '/carts',
        exact: true,
        component: lazy(() => import('pages/Carts')),
      },
      {
        path: '/messages',
        exact: true,
        component: lazy(() => import('pages/Messages')),
      },
      {
        path: '/pack-sanitaire',
        exact: true,
        component: lazy(() => import('pages/PackPage')),
      },
      {
        path: '/cyber_products',
        exact: true,
        component: lazy(() => import('pages/Catalogue/components/CyberProducts')),
      },
      {
        path: '/inspections',
        exact: true,
        component: lazy(() => import('pages/Inspections_test')),
      },
      {
        component: () => <Redirect to="/dashboard" />,
      },
    ],
  },
];

export default routes;
