import React, { useRef, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import { useSelector, useDispatch } from 'react-redux';
import { Popper, ListItem, List, Card, IconButton, Button, ListItemAvatar, ListItemText, ListItemSecondaryAction } from '@material-ui/core'
import useRouter from 'utils/useRouter';
import { FormattedMessage, injectIntl, IntlProvider } from 'react-intl';

import { ReactComponent as IconFab } from 'assets/svgs/fabicon.svg';
import useConversationContext from 'pages/Messages/hook';
import { ContactModal } from 'components/Modals'
import { setContactModal } from 'redux/actions/app'

const useStyles = makeStyles((theme) => ({
    root: {
        transform: 'translateZ(0px)',
        flexGrow: 1,
        position: 'fixed',
        right: 30,
        bottom: 30,
        zIndex: 9999
    },
    speedDial: {
        display: 'inline-block'
    },
    normalFab: {
        backgroundColor: '#05A969',
        color: 'white',
        fill: 'white',
        width: 49,
        height: 49,
        '&:hover': {
            backgroundColor: `${'#05A969'} !important`,
            color: 'white',
            fill: 'white',
        }
    },
    popover: {
        minWidth: 200,
        backgroundColor: theme.palette.app.background,
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(4),
        borderRadius: 10
    },
    title: {
        color: theme.palette.app.background,
    },
    avatar: {
        minWidth: 'auto',
        marginRight: theme.spacing(1.5)
    },
    iconstyle: {
        fill: theme.palette.app.primary,
        width: 18,
        height: 18
    },
    largeIconstyle: {
        fill: 'white',
        width: 25,
        height: 25
    },
    pinBtn: {
        padding: 2,
        '&:hover': {
            backgroundColor: 'transparent !important',
            fill: 'inherit !important',
            color: 'inherit !important'
        }
    },
    pinIcon: {
        width: 18,
        height: 18
    },
    menuBtn: {
        marginRight: theme.spacing(1),
        boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',
        backgroundColor: theme.palette.app.primary,
        fill: 'white',
        color: 'white',
        padding: 12
    },
    dialicon: {
        color: 'white',
        fill: 'white',
    },
    dialinIcon: {
        width: 40,
        height: 40,
        color: 'white',
        fill: 'white',
    },
    menutext: {
        fontWeight: '500 !important'
    }
}));

function FabButton({ intl: { formatMessage } }) {
    const classes = useStyles();
    const peaceRef = useRef();
    const [open, setOpen] = React.useState(false);
    const { loadChannels } = useConversationContext()
    const { openContactModal } = useSelector(state => state.app)
    const dispatch = useDispatch()
    const handleOpen = () => {
        dispatch(setContactModal({
            type: "fab"
        }))
    };

    return (
        <div className={classes.root}>
            {/* <SpeedDial
                ariaLabel="SpeedDial tooltip example"
                className={classes.speedDial}
                icon={<SpeedDialIcon className={classes.dialicon} icon={<IconFab className={classes.dialinIcon} />} openIcon={<IconFab className={classes.dialinIcon} />} />}
                open={open}
                ref={peaceRef}
                classes={{
                    fab: classes.normalFab
                }}
                FabProps={{ onClick: handleOpen }}
                direction='left'
            >
                <Popper
                    open={open}
                    anchorEl={peaceRef.current}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    placeholder="left-start"
                    disablePortal
                >
                    <Card className={classes.popover}>
                        <List>
                            {
                                // Object.keys(ITEMS).map(key => {
                                //     const isPin = selectedMenues.includes(key)
                                //     return <ListItem key={key} button onClick={() => onClickItem(ITEMS[key].link)}>
                                //         <ListItemAvatar className={classes.avatar}>
                                //             {ITEMS[key].icon}
                                //         </ListItemAvatar>
                                //         <ListItemText primary={formatMessage({ id: 'appmenu.' + key })} primaryTypographyProps={{ className: classes.menutext }} />
                                //         <ListItemSecondaryAction>
                                //             <IconButton className={classes.pinBtn} size="small"
                                //                 onClick={() => {
                                //                     dispatch(updateCoyabMenu(isPin ? selectedMenues.filter(item => item != key) : [...selectedMenues, key]))
                                //                 }}
                                //                 style={!isPin ? { color: '#93A1BD', fill: '#93A1BD' } : {}}
                                //             >
                                //                 {isPin ? <PushPinFillIcon className={classes.pinIcon} /> : <PushPinIcon className={classes.pinIcon} />}
                                //             </IconButton>
                                //         </ListItemSecondaryAction>
                                //     </ListItem>
                                // })
                            }
                        </List>
                    </Card>
                </Popper >
            </SpeedDial> */}

            <ContactModal
                appId="0000"
                visible={openContactModal}
                onClose={(refresh) => {
                    dispatch(setContactModal(false))
                    if (refresh) {
                        loadChannels()
                    }
                }}
            />
        </div>
    );
}
export default injectIntl(FabButton)