import React from 'react';
import ProfileSettings from 'pages/ProfileSettings';

import { ReactComponent as ExitIcon } from 'assets/svgs/Login.svg';
import { ReactComponent as RulerIcon } from 'assets/svgs/Ruler.svg';
import MailIcon from '@material-ui/icons/Mail'
import DashboardIcon from '@material-ui/icons/Dashboard'
import DocumentIcon from '@material-ui/icons/Folder'
import UserIcon from '@material-ui/icons/Person'
import StorefrontIcon from '@material-ui/icons/Storefront';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as IconDashboard } from 'assets/svgs/menu_dashboard.svg';
import { ReactComponent as IconPlanning } from 'assets/svgs/menu_planning.svg';
import { ReactComponent as IconDocuments } from 'assets/svgs/menu_documents.svg';
import { ReactComponent as IconCatalouge } from 'assets/svgs/menu_catalogue.svg';
import { ReactComponent as IconAccount } from 'assets/svgs/menu_account.svg';
import { ReactComponent as IconLogout } from 'assets/svgs/menu_logout.svg';
import { ReactComponent as IconService } from 'assets/svgs/menu_services.svg';

export default function GetMenus() {
  return {
    top: [
      {
        id: 'notifications',
        text: <FormattedMessage id="navbar.notifications" />,
        icon: <MailIcon className="side-bar-svg" />,
        components: ProfileSettings,
        index: 0,
      },
    ],
    middle: [
      {
        id: 'dashboard',
        text: <FormattedMessage id="navbar.dashboard" />,
        icon: <DashboardIcon className="side-bar-svg" />,
        url: '/dashboard',
        index: 11,
      },
      {
        id: 'planning',
        text: <FormattedMessage id="navbar.planning" />,
        icon: <IconPlanning className="side-bar-svg" />,
        url: '/planning',
        index: 11,
      },
      {
        id: 'documents',
        text: <FormattedMessage id="navbar.documents" />,
        icon: <IconDocuments className="side-bar-svg" />,
        url: '/documents',
        index: 12,
      },
      // {
      //   id: 'applications',
      //   text: <FormattedMessage id="navbar.services" />,
      //   icon: <IconService className="side-bar-svg" />,
      //   url: '/applications',
      //   index: 13,
      // },
      {
        id: 'catalogue',
        text: <FormattedMessage id="navbar.catalogue" />,
        icon: <IconCatalouge className="side-bar-svg" />,
        url: '/catalogue',
        index: 13,
      },
    ],
    bottom: [
      {
        id: 'profile',
        text: <FormattedMessage id="navbar.profile" />,
        icon: <IconAccount className="side-bar-svg" />,
        url: '/profile',
        index: 21,
      },
      {
        id: 'exit',
        text: <FormattedMessage id="navbar.exit" />,
        icon: <IconLogout className="side-bar-svg" />,
        index: 22,
      },
    ],
  };
}
